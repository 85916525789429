import authAxios from 'modules/shared/axios/authAxios';
import { getAvailableDatesFromOverAllDateRange } from '~/utils/dateUtil';
import { CampaignService } from '~/modules/campaign/service';
import { format, parse } from 'date-fns';
import { CSV_EXTENSION, CSV_TYPE } from '~/modules/shared/excel/excel';
import FileSaver from 'file-saver';
import { iInventory } from '~/types/models/inventory/inventory';
import { WithRowsAndCount } from '~/types/modules/common';
import { Pricing } from '~/types/models/inventory/pricing';
import {
  InventoryAttribution,
  InventoryAttributionModel,
} from '~/types/models/inventory/inventoryAttribution';

export default class StorefrontService {
  static async getAttribution(storefrontId: string): Promise<InventoryAttribution[]> {
    const response = await authAxios.get<InventoryAttributionModel[]>(
      `/analytics/attribution/inventory/${storefrontId}/report`,
    );
    return response.data.map((inventoryAttributionModel) =>
      InventoryAttribution.create(inventoryAttributionModel),
    );
  }

  static async getPricing(id) {
    const response = await authAxios.get<Pricing>(`/storefronts/${id}/pricing`);
    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/storefront/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const response = await authAxios.delete('/storefront', {
      data: ids,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };
    const response = await authAxios.post('/storefront', body);

    return response.data;
  }

  static async import(values, importHash, id) {
    const body = {
      data: values,
      importHash,
      id,
    };
    const response = await authAxios.post('/storefront/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/storefront/${id}`);
    return response.data;
  }

  static async findByIntegerId(iid) {
    const response = await authAxios.get(`/storefronts/map/iid/${iid}`);
    return response.data;
  }

  static async getStorefrontStats(storefrontId, extra = {}) {
    let params = '';
    let range = null;
    if (extra?.mode) {
      params += `&mode=${extra.mode}`;
    }
    if (extra?.dates) {
      if (extra.dates?.range) {
        range = extra.dates.range.toString();
        params += `&range=${range}`;
      }
    }

    if (range !== null) {
      const response = await authAxios.get(
        `/storefront/statsFast/?storefrontId=${storefrontId}${params}`,
      );
      return response.data;
    }
  }

  static async findAndCountStorefrontsByPartnerId(
    partnerId: string,
    limit?: number,
    offset?: number,
    search?: string,
    excludePackages?: boolean,
  ): Promise<WithRowsAndCount<iInventory>> {
    const params = {
      partnerId,
      limit,
      offset,
      search,
      excludePackages,
    };
    const response = await authAxios.get(`/storefronts/partner`, { params });
    return response.data;
  }

  static async getUserLocations(userid) {
    const response = await authAxios.get(`/storefront/locations/?userid=${userid}`);
    return response.data;
  }

  static async tosAgree(userid) {
    const response = await authAxios.get(`/storefront/tosAgree/?userid=${userid}`);
    return response.data;
  }

  static async selectUserOrganizations(userid) {
    const response = await authAxios.get(`/storefront/user_organization/?userid=${userid}`);
    return response.data;
  }

  static async selectUserStorefronts(userid) {
    const response = await authAxios.get(`/storefront/user_storefronts/?userid=${userid}`);
    return response.data;
  }

  static async getAnalyticsAthena(storefrontId) {
    const response = await authAxios.get(
      `/storefront/${storefrontId?.storefrontId}/analytics/athena`,
    );
    return response.data;
  }

  static async selectChannelUserIds(userid) {
    const response = await authAxios.get(`/storefront/channel_users/?userid=${userid}`);
    return response.data;
  }

  static async createDeviceLogs(data) {
    const body = {
      data,
    };
    const response = await authAxios.post('/storefront/device_logs', body);

    return response.data;
  }

  static async createDeviceScans(data) {
    const body = {
      data,
    };
    const response = await authAxios.post('/storefront/device_scans', body);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get('/storefront', {
      params,
    });
    return response.data;
  }

  static async listByAvailability(startDate, endDate, options) {
    const params = {};
    const response = await authAxios.get('/storefront/available', {
      params,
    });

    let storefronts;
    if (startDate && endDate) {
      const blockingCampaignStatuses = (await CampaignService.getStatusMetadata()).metadata
        .filter((x) => x.isBlocking)
        .map((x) => x.name);

      const applyFilter = (storefront) =>
        options?.additionalFilter?.(storefront) || storefront.availabilityDays.length > 0;

      storefronts = response.data.rows
        .map((storefront) => {
          storefront.availabilityDays = [
            ...getAvailableDatesFromOverAllDateRange(
              startDate,
              endDate,
              storefront.flights
                .filter(
                  (flight) =>
                    flight?.campaign?.status == null ||
                    blockingCampaignStatuses.includes(flight?.campaign?.status),
                )
                .map((flight) => [
                  parse(flight.startDate, 'MM/dd/yyyy', new Date()),
                  parse(flight.endDate, 'MM/dd/yyyy', new Date()),
                ]),
            ),
          ].map((date) => format(date, 'P'));

          return storefront;
        })
        .filter(applyFilter);
    } else {
      storefronts = response.data.rows;
    }

    return storefronts;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };
    const response = await authAxios.get('/storefront/autocomplete', {
      params,
    });

    return response.data;
  }

  static async upsertScheduleBlock(data) {
    const response = await authAxios.post('/storefront/scheduleBlock', {
      data,
    });

    return response.data;
  }

  static async exportToCSV(data) {
    const response = await authAxios.post('/storefronts/export', {
      data,
    });

    const file = new Blob([response.data], {
      type: CSV_TYPE,
    });

    FileSaver.saveAs(file, 'Inventory' + CSV_EXTENSION);
  }

  static async deleteScheduleBlock(data) {
    const response = await authAxios.delete('/storefront/scheduleBlock', {
      data,
    });

    return response.data;
  }

  static async getScheduleBlocks(data) {
    const response = await authAxios.get('/storefront/scheduleBlock', {
      data,
    });

    return response.data;
  }

  static async userScheduleBlocks(data) {
    const response = await authAxios.get('/scheduler', {
      data,
    });

    return response.data;
  }

  static async getGeoJSONByQuery(body, query = {}) {
    const response = await authAxios.post('/storefronts/map/storefront', body, {
      params: {
        ...query,
      },
    });

    return response.data;
  }

  static async getGeoJSONFilter() {
    const response = await authAxios.get('/storefronts/map/geojson/filters');

    return response.data;
  }

  static async getGeoJSONFilterV3() {
    const response = await authAxios.get('/storefronts/map/geojson/filter');

    return response.data;
  }

  static async getFilterList(query, limit) {
    const params = {
      query,
      limit,
    };
    const response = await authAxios.get('/filters/storefront', {
      params,
    });

    return response.data;
  }

  static async setFilters(filter, limit) {
    const filtered = {};
    if (filter?.items) {
      for (const i in filter.items) {
        const thisFilter = filter.items[i];
        if (thisFilter.filterKey) {
          if (!filtered[thisFilter.filterKey]) {
            filtered[thisFilter.filterKey] = [];
          }
          if (thisFilter.filterKey !== 'name') {
            filtered[thisFilter.filterKey].push(thisFilter.key);
          } else {
            filtered[thisFilter.filterKey].push(thisFilter.label);
          }
        }
      }

      /*
       *For (var i in filtered) {
       *	let thisFilter = filtered[i]
       *	if (thisFilter.length === 1) {
       *		filtered[i] = thisFilter[0]
       *	}
       *}
       */
    }

    const params = {
      filter: filtered,
      limit,
    };
    const response = await authAxios.get('/storefront', {
      params,
    });

    return response.data;
  }

  static async refreshStatus(id) {
    const params = {};
    const response = await authAxios.get(`/refresh_gmaps_data/${id}`, {
      params,
    });

    return response.data;
  }

  static async getStorefrontPackage(id) {
    const response = await authAxios.get(`/storefronts/${id}/package`);
    return response.data;
  }

  static async getStorefrontPackages(query) {
    const response = await authAxios.get(`/storefronts/packages${query}`);

    return response.data;
  }

  static async getStorefrontPackagePartners(query) {
    const response = await authAxios.get(
      `/storefronts/packages/partners?searchValue=${query}&limit=100`,
    );
    return response.data;
  }

  static async getStorefrontPackageDMAs(query) {
    const response = await authAxios.get(
      `/storefronts/packages/dmas?searchValue=${query}&limit=100`,
    );
    return response.data;
  }

  static async getStorefrontPackageFormats(query) {
    const response = await authAxios.get(
      `/storefronts/packages/formats?searchValue=${query}&limit=100`,
    );
    return response.data;
  }
}
